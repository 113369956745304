import { FunctionComponent, useEffect } from 'react'
import { useRouter } from 'next/router'

const Custom404 = (): FunctionComponent => {
  const router = useRouter()

  useEffect(() => {
    void router.replace('/processing')
  })

  return null
}

export default Custom404
